import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './Store/store';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import {
  Admin, Auth, Blog, Cheatsheet, Dashboard, Error, Home, Jobs, Learn, Projects, SearchPage
} from './Pages/index';
import {
  BlogDetails, TutorialDetails, Profile, CheatsheetDetails, PrivacyPolicy
} from './Components/index';
import useSEO from './Hooks/useDynamicTitle';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/blogs', element: <Blog /> },
      { path: '/blogs/:slug', element: <BlogDetails /> },
      { path: '/learn', element: <Learn /> },
      { path: '/learn/:slug', element: <TutorialDetails /> },
      { path: '/projects', element: <Projects /> },
      { path: '/cheatsheet', element: <Cheatsheet /> },
      { path: '/cheatsheet/:slug', element: <CheatsheetDetails /> },
      { path: '/profile', element: <Profile /> },
      { path: '/dashboard/*', element: <Dashboard /> },
      { path: '/admin/*', element: <Admin /> },
      { path: '/jobs', element: <Jobs /> },
      { path: '/search', element: <SearchPage /> },
      { path: '/auth/*', element: <Auth /> },
      { path: '*', element: <Error /> },
      { path: '/privacy-policy', element: <PrivacyPolicy /> },

    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <RouterProvider router={router} />
      </HelmetProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
