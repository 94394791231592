import React from 'react';
import { FaBlog, FaProjectDiagram, FaBriefcase, FaClipboardList, FaCalendarAlt, FaBook, FaCode } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const categories = [
  { name: 'Blog', icon: <FaBlog /> },
  { name: 'Project', icon: <FaProjectDiagram /> },
  { name: 'Freelance', icon: <FaBriefcase /> },
  { name: 'Job', icon: <FaClipboardList /> },
  { name: 'Event', icon: <FaCalendarAlt /> },
  { name: 'Tutorials', icon: <FaBook /> },
  { name: 'Cheatsheet', icon: <FaCode /> },
  { name: 'More Categories', icon: <FaClipboardList /> }
];

const FeaturedCategories = () => {
  return (
    <section className="py-1">
      <div className="container">
        <h2 className="text-3xl font-bold mb-5 text-gray-800">Featured Categories</h2>
        <Swiper
          spaceBetween={30}
          slidesPerView={4.5}
          grabCursor={true}
          breakpoints={{
            100: {
              slidesPerView: 2.5,
            },
            640: {
              slidesPerView: 3.5,
            },
            768: {
              slidesPerView: 4.5,
            },
            1024: {
              slidesPerView: 5.5,
            },
          }}
        >
          {categories.map((category, index) => (
            <SwiperSlide key={index}>
              <div className="flex flex-col items-center p-4 transition-transform duration-300 hover:scale-105 cursor-pointer">
                <div className="text-5xl text-blue-500 mb-2">{category.icon}</div>
                <h3 className="text-lg font-semibold">{category.name}</h3>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default FeaturedCategories;
