import React from 'react';
import { FaBriefcase, FaClipboardList, FaUserTie, FaBuilding, FaHandshake, FaBullhorn } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Job categories data
const jobCategories = [
  { name: 'Full-Time', icon: <FaBriefcase /> },
  { name: 'Part-Time', icon: <FaClipboardList /> },
  { name: 'Remote', icon: <FaUserTie /> },
  { name: 'Internship', icon: <FaBuilding /> },
  { name: 'Contract', icon: <FaHandshake /> },
  { name: 'Freelance', icon: <FaBullhorn /> }
];

const JobSection = () => {
  return (
    <section className="py-1">
      <div className="container">
        <h2 className="text-3xl font-bold mb-5 text-gray-800">Explore Job Opportunities</h2>
        <Swiper
          spaceBetween={30}
          slidesPerView={4.5}
          grabCursor={true}
          breakpoints={{
            100: {
              slidesPerView: 2.5,
            },
            640: {
              slidesPerView: 3.5,
            },
            768: {
              slidesPerView: 4.5,
            },
            1024: {
              slidesPerView: 6.5,
            },
          }}
        >
          {jobCategories.map((category, index) => (
            <SwiperSlide key={index}>
              <div className="flex flex-col items-center p-4 transition-transform duration-300 hover:scale-105 cursor-pointer">
                <div className="text-5xl text-blue-500 mb-2">{category.icon}</div>
                <h3 className="text-lg font-semibold">{category.name}</h3>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default JobSection;
