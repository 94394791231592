import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FaArrowRight, FaClock, FaRegComments } from 'react-icons/fa';

// Mock data for blogs
const blogs = [
  {
    title: 'Mastering React Hooks',
    category: 'React',
    date: 'Aug 20, 2024',
    readTime: '5 min read',
    comments: 12,
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbldKXAHsElaI62wfiJH9lujiJdzBA80mUyg&s', // Placeholder image
  },
  {
    title: 'Advanced JavaScript Tips',
    category: 'JavaScript',
    date: 'Aug 18, 2024',
    readTime: '7 min read',
    comments: 18,
    image: 'https://miro.medium.com/v2/resize:fit:1400/1*YclJ1hp8CgncNJiFmZCCmg.jpeg',
  },
  // Add more blog items...
];

const LatestBlog = () => {
  return (
    <section className="py-1">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-5 text-gray-800">Latest Blogs</h2>

        {/* Blog Carousel */}
        <Swiper
          spaceBetween={30}
          slidesPerView={4.5}
          pagination={{ clickable: true }}
          breakpoints={{
            100: {
              slidesPerView: 1.10,
            },
            640: {
              slidesPerView: 2.5,
            },
            1024: {
              slidesPerView: 4.5,
            },
          }}
        >
          {blogs.map((blog, index) => (
            <SwiperSlide key={index}>
              <div className="bg-white shadow-lg rounded-lg overflow-hidden transition-transform duration-300 hover:scale-105">
                <img src={blog.image} alt={blog.title} className="w-full h-48 object-cover" />
                <div className="p-4">
                  <h3 className="text-lg font-semibold mb-2">{blog.title}</h3>
                  <div className="flex items-center justify-between text-gray-500 text-sm mb-2">
                    <span className="flex items-center"><FaClock className="mr-1" />{blog.date}</span>
                    <span className="flex items-center"><FaRegComments className="mr-1" />{blog.comments} comments</span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-700">{blog.readTime}</span>
                    <button className="text-blue-500 flex items-center hover:underline">
                      Read More <FaArrowRight className="ml-2" />
                    </button>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default LatestBlog;
