import React, { useState, useEffect } from 'react';
import { FaDownload, FaExternalLinkAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';

// Sample project data with realistic names and icons
const initialProjects = [
  {
    id: 1,
    title: 'Portfolio Website',
    liveLink: 'https://your-portfolio-site.com',
    description: 'A personal portfolio website showcasing projects, skills, and contact information.',
    downloadLink: 'https://github.com/yourusername/portfolio',
    icon: 'https://via.placeholder.com/50?text=Portfolio',
    coverImage: 'https://via.placeholder.com/600x400?text=Portfolio+Website',
  },
  {
    id: 2,
    title: 'E-commerce Platform',
    liveLink: 'https://your-ecommerce-site.com',
    description: 'An e-commerce platform with product listings, shopping cart, and payment integration.',
    downloadLink: 'https://github.com/yourusername/e-commerce-platform',
    icon: 'https://via.placeholder.com/50?text=E-commerce',
    coverImage: 'https://via.placeholder.com/600x400?text=E-commerce+Platform',
  },
  {
    id: 3,
    title: 'Blog Application',
    liveLink: 'https://your-blog-site.com',
    description: 'A blogging platform allowing users to create, edit, and comment on blog posts.',
    downloadLink: 'https://github.com/yourusername/blog-application',
    icon: 'https://via.placeholder.com/50?text=Blog',
    coverImage: 'https://via.placeholder.com/600x400?text=Blog+Application',
  },
  {
    id: 4,
    title: 'Task Management Tool',
    liveLink: 'https://your-task-manager.com',
    description: 'A task management tool for organizing and tracking tasks and projects.',
    downloadLink: 'https://github.com/yourusername/task-manager',
    icon: 'https://via.placeholder.com/50?text=Task+Manager',
    coverImage: 'https://via.placeholder.com/600x400?text=Task+Management+Tool',
  },
  {
    id: 5,
    title: 'Weather App',
    liveLink: 'https://your-weather-app.com',
    description: 'A weather application providing real-time weather updates and forecasts.',
    downloadLink: 'https://github.com/yourusername/weather-app',
    icon: 'https://via.placeholder.com/50?text=Weather',
    coverImage: 'https://via.placeholder.com/600x400?text=Weather+App',
  },
  {
    id: 6,
    title: 'Chat Application',
    liveLink: 'https://your-chat-app.com',
    description: 'A real-time chat application with messaging and user management features.',
    downloadLink: 'https://github.com/yourusername/chat-app',
    icon: 'https://via.placeholder.com/50?text=Chat',
    coverImage: 'https://via.placeholder.com/600x400?text=Chat+Application',
  },
];

const Projects = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage] = useState(3);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setProjects(initialProjects);
      setFilteredProjects(initialProjects.slice(0, projectsPerPage));
      setLoading(false);
    }, 1000);
  }, [projectsPerPage]);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredProjects(
      projects
        .filter(project => project.title.toLowerCase().includes(value))
        .slice(0, currentPage * projectsPerPage)
    );
  };

  const handleLoadMore = () => {
    setCurrentPage(prevPage => {
      const newPage = prevPage + 1;
      setFilteredProjects(
        projects
          .filter(project => project.title.toLowerCase().includes(searchTerm))
          .slice(0, newPage * projectsPerPage)
      );
      return newPage;
    });
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-extrabold mb-10 text-gray-900 text-center">Projects</h1>

      {/* Search Bar */}
      <div className="mb-8 flex justify-center">
        <input
          type="text"
          placeholder="Search projects..."
          value={searchTerm}
          onChange={handleSearch}
          className="px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-teal-500 w-full md:w-2/3 lg:w-1/2"
          aria-label="Search projects"
        />
      </div>

      {/* Projects Grid */}
      {loading ? (
        <div className="text-center text-gray-600">Loading...</div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
          {filteredProjects.length > 0 ? (
            filteredProjects.map((project) => (
              <motion.div
                key={project.id}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-white shadow-lg rounded-lg overflow-hidden transition-transform"
              >
                <img
                  src={project.coverImage}
                  alt={project.title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <div className="flex items-center mb-4">
                    <img src={project.icon} alt={`${project.title} icon`} className="w-12 h-12 mr-4 rounded-full border border-gray-200" />
                    <h2 className="text-2xl font-semibold text-gray-800">{project.title}</h2>
                  </div>
                  <p className="text-gray-700 mb-5">{project.description}</p>
                  <div className="flex flex-col space-y-3">
                    <a
                      href={project.liveLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center text-teal-600 hover:text-teal-800 transition-colors duration-300"
                      aria-label={`Live demo of ${project.title}`}
                    >
                      <FaExternalLinkAlt className="mr-2" />
                      Live Demo
                    </a>
                    <a
                      href={project.downloadLink}
                      download
                      className="flex items-center text-teal-600 hover:text-teal-800 transition-colors duration-300"
                      aria-label={`Download ${project.title}`}
                    >
                      <FaDownload className="mr-2" />
                      Download
                    </a>
                  </div>
                </div>
              </motion.div>
            ))
          ) : (
            <p className="text-center col-span-full text-gray-500">No projects found</p>
          )}
        </div>
      )}

      {/* Load More Button */}
      {!loading && filteredProjects.length < projects.length && (
        <div className="mt-8 text-center">
          <button
            onClick={handleLoadMore}
            className="px-6 py-3 bg-teal-600 text-white rounded-lg hover:bg-teal-700 transition-colors duration-300"
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default Projects;
