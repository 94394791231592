// src/Pages/Cheatsheet.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FaJava, FaNodeJs, FaReact, FaJsSquare } from 'react-icons/fa'; // Import relevant icons

// Function to assign colors to icons
const getIconColor = (id) => {
  switch (id) {
    case 'java':
      return 'text-red-600'; // Red for Java
    case 'nodejs':
      return 'text-green-600'; // Green for Node.js
    case 'react':
      return 'text-blue-600'; // Blue for React
    case 'javascript':
      return 'text-yellow-600'; // Yellow for JavaScript
    default:
      return 'text-gray-600'; // Default color
  }
};

const cheatsheets = [
  { id: 'java', name: 'Java', icon: <FaJava className="text-4xl" /> },
  { id: 'nodejs', name: 'Node.js', icon: <FaNodeJs className="text-4xl" /> },
  { id: 'react', name: 'React', icon: <FaReact className="text-4xl" /> },
  { id: 'javascript', name: 'JavaScript', icon: <FaJsSquare className="text-4xl" /> },
  // Add more languages as needed
];

const Cheatsheet = () => {
  return (
    <div className="container mx-auto px-4 py-6">
      <h1 className="text-3xl font-bold mb-6">Cheatsheets</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {cheatsheets.map((cheatsheet) => (
          <Link
            to={`/cheatsheet/${cheatsheet.id}`}
            key={cheatsheet.id}
            className={`bg-white shadow-md rounded-lg p-4 flex flex-col items-center text-center hover:shadow-lg ${getIconColor(cheatsheet.id)}`}
          >
            {React.cloneElement(cheatsheet.icon, { className: `text-4xl ${getIconColor(cheatsheet.id)}` })}
            <h2 className="text-xl font-semibold mt-2">{cheatsheet.name}</h2>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Cheatsheet;



