import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const dummyBlogPosts = [
  {
    id: 1,
    coverImage: 'https://via.placeholder.com/300x200?text=Component+Library',
    title: 'Building a Component Library with Storybook and React',
    shortDescription: 'Learn how to build a component library using Storybook and React for reusable UI components.',
    categories: ['React', 'Storybook', 'Component Library'],
  },
  {
    id: 2,
    coverImage: 'https://via.placeholder.com/300x200?text=React+Hooks',
    title: 'Understanding React Hooks',
    shortDescription: 'A comprehensive guide to understanding and using React Hooks in your projects.',
    categories: ['React', 'Hooks', 'JavaScript'],
  },
  {
    id: 3,
    coverImage: 'https://via.placeholder.com/300x200?text=JavaScript+Tips',
    title: 'JavaScript Tips and Tricks',
    shortDescription: 'Enhance your JavaScript skills with these practical tips and tricks.',
    categories: ['JavaScript', 'Tips', 'Development'],
  },
];

const Blog = () => {
  return (
    <div className="container mx-auto px-4 py-6 min-h-screen">
      <h1 className="text-4xl font-bold text-center mb-10">Latest Blog Posts</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {dummyBlogPosts.map((post) => (
          <motion.div
            key={post.id}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300"
          >
            <img
              src={post.coverImage}
              alt={post.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-5">
              <h2 className="text-2xl font-bold mb-2">{post.title}</h2>
              <p className="text-gray-700 mb-4">{post.shortDescription}</p>
              <div className="flex flex-wrap gap-2 mb-4">
                {post.categories.map((category, index) => (
                  <span
                    key={index}
                    className="bg-indigo-100 text-indigo-800 text-sm font-medium py-1 px-2 rounded"
                  >
                    {category}
                  </span>
                ))}
              </div>
              <Link
                to={`/blog/${post.id}`}
                className="inline-block text-indigo-600 hover:text-indigo-800 hover:underline font-semibold transition duration-300"
              >
                Read more
              </Link>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
