import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {DashboardAnalytics,
  DashboardSlider,
  ManagePosts,
  Overview,
  PostBlogForm,
  PostCheatsheetForm,
  PostJobForm,
  PostProjectForm,
  PostTutorialForm,
  PostItem,
  UserPosts} from '../Components/index';

const Dashboard = () => {
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <DashboardSlider />
        <div style={{ flex: 1, padding: '20px' }}>
          <Routes>
            <Route path="overview" element={<Overview />} />
            <Route path="analytics" element={<DashboardAnalytics />} />
            <Route path="manage-posts" element={<ManagePosts />} />
            <Route path="post-blog" element={<PostBlogForm />} />
            <Route path="post-job" element={<PostJobForm />} />
            <Route path="post-tutorial" element={<PostTutorialForm />} />
            <Route path="post-project" element={<PostProjectForm />} />
            <Route path="post-cheatsheet" element={<PostCheatsheetForm />} />
            <Route path="user-posts" element={<UserPosts />} />
            <Route path="post/:id" element={<PostItem />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
