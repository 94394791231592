import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FaUser, FaQuoteLeft } from 'react-icons/fa';

const userStories = [
  {
    name: 'Aarav Patel',
    story: 'This platform has been a fantastic resource for my career growth. The project management tools are incredibly effective.',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBCjaE3kchRqKg8UQZ9M5aV24K5XVrNi0N7w&s',
  },
  {
    name: 'Isha Sharma',
    story: 'The tutorials and articles available here are top-notch. I’ve gained so much knowledge and practical skills.',
    image: 'https://i.pinimg.com/736x/af/0d/7c/af0d7c8ce434deb503432cc5fce2c326.jpg',
  },
  {
    name: 'Ravi Kumar',
    story: 'Connecting with professionals through this platform has been amazing. The community support is truly invaluable.',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7MNEsjegBAu6iIU2mDe9uzhmiOvYwxBBZs-3x5Eptlr73UuTK-ur-Ux2rxmCUAF_hvpw&usqp=CAU',
  },
  {
    name: 'Priya Gupta',
    story: 'The career advice section has been a game changer for me. The guidance and tips have really helped in my professional journey.',
    image: 'https://i.pinimg.com/736x/1c/42/db/1c42dbe4cfb44025ac69d041568cf2c5.jpg',
  },
  {
    name: 'Kabir Singh',
    story: 'I love the user-friendly design of the platform. It’s easy to navigate and find exactly what I need.',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVoiB2UFHMrcFDZzhhnaBbrrDUwQuZRz2enyrdLLh_vNhxea1_TvZnSdEC1VGrBT1zTKg&usqp=CAU',
  },
];

const UserStories = () => {
  return (
    <section className="py-10">
      <div className="container ">
        <h2 className="text-3xl font-bold mb-5 text-gray-800">User Stories</h2>

        <Swiper
          spaceBetween={20}
          slidesPerView={3.5}
          breakpoints={{
            320: {
              slidesPerView: 1.1,
            },
            640: {
              slidesPerView: 2.5,
            },
            768: {
              slidesPerView: 2.5,
            },
            1024: {
              slidesPerView: 3.5,
            },
          }}
        >
          {userStories.map((story, index) => (
            <SwiperSlide key={index}>
              <div className="bg-white shadow-lg rounded-lg overflow-hidden transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
                <div className="flex items-center p-5">
                  <img src={story.image} alt={story.name} className="w-16 h-16 rounded-full object-cover mr-4" />
                  <div>
                    <h3 className="text-lg font-semibold text-gray-800">{story.name}</h3>
                    <p className="text-gray-600 mt-2">
                      <FaQuoteLeft className="inline-block mr-2 text-blue-500" />
                      {story.story}
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}

          {/* Button at the End */}
          <SwiperSlide>
            <div className="flex justify-center items-center h-full">
              <button
                onClick={() => window.location.href = '/all-user-stories'}
                className="bg-gradient-to-r from-blue-500 to-purple-500 text-white py-3 px-8 rounded-full hover:bg-gradient-to-r hover:from-purple-500 hover:to-blue-500 transition-all duration-300 shadow-lg"
              >
                See All User Stories
              </button>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default UserStories;
