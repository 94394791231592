import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FaClock, FaRegComments } from 'react-icons/fa';

const popularPosts = [
  {
    title: 'How to Master React Hooks',
    category: 'React',
    date: 'Aug 20, 2024',
    readTime: '5 min read',
    comments: 14,
    image: 'https://miro.medium.com/v2/resize:fit:1400/1*d044eA8ePGr0xYZXVSeXqg.png',
  },
  {
    title: 'JavaScript ES6 Features You Should Know',
    category: 'JavaScript',
    date: 'Aug 18, 2024',
    readTime: '7 min read',
    comments: 21,
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNXXc8epn4rVXHHadh6ENUoRrJwV4nr8xH-A&s',
  },
  {
    title: 'Introduction to TypeScript',
    category: 'TypeScript',
    date: 'Aug 17, 2024',
    readTime: '6 min read',
    comments: 11,
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQInjsBgIErAVZp84FUrKkypJ0knuICbPdX4w&s',
  },
  {
    title: 'Building Responsive Layouts with Flexbox',
    category: 'CSS',
    date: 'Aug 15, 2024',
    readTime: '4 min read',
    comments: 8,
    image: 'https://i.ytimg.com/vi/m8BSEUUB5so/maxresdefault.jpg',
  },
  {
    title: 'Best Practices for Node.js Development',
    category: 'Node.js',
    date: 'Aug 14, 2024',
    readTime: '8 min read',
    comments: 13,
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwI5zYfmdlN43vUNOwgeUAq3GLIWqXSDsltg&s',
  },
];

const PopularPosts = () => {
  return (
    <section className="py-10">
      <div className="container">
        <h2 className="text-3xl font-bold mb-5 text-gray-800">Popular Posts</h2>

        <Swiper
          spaceBetween={20}
          slidesPerView={4.5}
          breakpoints={{
            320: {
              slidesPerView: 1.1,
            },
            640: {
              slidesPerView: 2.5,
            },
            768: {
              slidesPerView: 3.5,
            },
            1024: {
              slidesPerView: 4.5,
            },
          }}
        >
          {popularPosts.slice(0, 5).map((post, index) => (
            <SwiperSlide key={index}>
              <div className="bg-white shadow-lg rounded-lg overflow-hidden transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
                <img src={post.image} alt={post.title} className="w-full h-48 object-cover" />
                <div className="p-5">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">{post.title}</h3>
                  <div className="flex items-center text-gray-500 text-sm mb-2">
                    <FaClock className="mr-2 text-blue-500" />
                    {post.date} • {post.readTime}
                  </div>
                  <div className="flex items-center text-gray-500 text-sm">
                    <FaRegComments className="mr-2 text-blue-500" />
                    {post.comments} comments
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}

          {/* Button at the End */}
          <SwiperSlide>
            <div className="flex justify-center items-center h-full">
              <button
                onClick={() => window.location.href = '/all-popular-posts'}
                className="bg-gradient-to-r from-blue-500 to-purple-500 text-white py-3 px-8 rounded-full hover:bg-gradient-to-r hover:from-purple-500 hover:to-blue-500 transition-all duration-300 shadow-lg"
              >
                See All Popular Posts
              </button>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default PopularPosts;
