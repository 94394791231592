// src/components/Learn.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FaPython, FaJava, FaJsSquare, FaDatabase, FaHtml5, FaCss3, FaReact, FaNodeJs } from 'react-icons/fa';

// Sample tutorial data
const tutorials = [
  { id: 1, name: 'Python', icon: <FaPython />, description: 'Learn Python programming language.', details: 'Detailed content about Python.' },
  { id: 2, name: 'Java', icon: <FaJava />, description: 'Learn Java programming language.', details: 'Detailed content about Java.' },
  { id: 3, name: 'JavaScript', icon: <FaJsSquare />, description: 'Learn JavaScript programming language.', details: 'Detailed content about JavaScript.' },
  { id: 4, name: 'Database', icon: <FaDatabase />, description: 'Learn about databases and SQL.', details: 'Detailed content about databases.' },
  { id: 5, name: 'HTML', icon: <FaHtml5 />, description: 'Learn HTML for web development.', details: 'Detailed content about HTML.' },
  { id: 6, name: 'CSS', icon: <FaCss3 />, description: 'Learn CSS for styling web pages.', details: 'Detailed content about CSS.' },
  { id: 7, name: 'React', icon: <FaReact />, description: 'Learn React for building UI components.', details: 'Detailed content about React.' },
  { id: 8, name: 'Node.js', icon: <FaNodeJs />, description: 'Learn Node.js for server-side programming.', details: 'Detailed content about Node.js.' },
];

const Learn = () => {
  return (
    <div className="container mx-auto px-4 py-6">
      <h1 className="text-3xl font-bold mb-6">Learn Tutorials</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {tutorials.map((tutorial) => (
          <div key={tutorial.id} className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="p-4 flex items-center space-x-4">
              <div className="text-4xl text-teal-600">{tutorial.icon}</div>
              <div>
                <h2 className="text-xl font-semibold mb-2">{tutorial.name}</h2>
                <p className="text-gray-600">{tutorial.description}</p>
                <Link
                  to={`/learn/${tutorial.id}`}
                  className="text-teal-600 hover:underline mt-2 inline-block"
                >
                  Read more
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Learn;
