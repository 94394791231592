import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FaClock, FaRegComments } from 'react-icons/fa';

const highlights = [
  {
    title: 'Top 5 React Libraries to Explore',
    category: 'React',
    date: 'Aug 21, 2024',
    readTime: '4 min read',
    comments: 8,
    image: 'https://media.geeksforgeeks.org/wp-content/uploads/20240222170028/Top-5-React-UI--component-Libraries-of-2024-copy.webp',
  },
  {
    title: 'JavaScript Best Practices',
    category: 'JavaScript',
    date: 'Aug 19, 2024',
    readTime: '6 min read',
    comments: 15,
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2GqqoPTla62a66sNcqTNsotM90R-wZSam7Q&s',
  },
  {
    title: 'Understanding CSS Grid',
    category: 'CSS',
    date: 'Aug 18, 2024',
    readTime: '5 min read',
    comments: 6,
    image: 'https://i.ytimg.com/vi/68O6eOGAGqA/maxresdefault.jpg',
  },
  {
    title: 'Exploring Node.js Frameworks',
    category: 'Node.js',
    date: 'Aug 17, 2024',
    readTime: '7 min read',
    comments: 12,
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzZhzwOwmptETzGJqGPEg6ZbGnleV7c2O2RA&s',
  },
  {
    title: 'Effective Git Workflows',
    category: 'Git',
    date: 'Aug 16, 2024',
    readTime: '3 min read',
    comments: 10,
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSncK7k7O94mzSWNhmo_Ym0j4wGe0snmrHvg&s',
  },
];

const WeeklyHighlight = () => {
  return (
    <section className="py-10">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-5 text-gray-800">Weekly Highlights</h2>

        <Swiper
          spaceBetween={20}
          slidesPerView={4.5}
          breakpoints={{
            100: {
              slidesPerView: 1.10,
            },
            640: {
              slidesPerView: 2.5,
            },
            1024: {
              slidesPerView: 3.5,
            },
            1280: {
              slidesPerView: 4.5,
            },
          }}
        >
          {highlights.slice(0, 5).map((highlight, index) => (
            <SwiperSlide key={index}>
              <div className="shadow-lg rounded-lg overflow-hidden transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
                <img src={highlight.image} alt={highlight.title} className="w-full h-48 object-cover" />
                <div className="p-5">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">{highlight.title}</h3>
                  <div className="flex items-center text-gray-500 text-sm mb-2">
                    <FaClock className="mr-2 text-blue-500" />
                    {highlight.date} • {highlight.readTime}
                  </div>
                  <div className="flex items-center text-gray-500 text-sm">
                    <FaRegComments className="mr-2 text-blue-500" />
                    {highlight.comments} comments
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}

          {/* Button at the End */}
          <SwiperSlide>
            <div className="flex justify-center items-center h-full">
              <button
                onClick={() => window.location.href = '/all-highlights'}
                className="bg-gradient-to-r from-blue-500 to-purple-500 text-white py-3 px-8 rounded-full hover:bg-gradient-to-r hover:from-purple-500 hover:to-blue-500 transition-all duration-300 shadow-lg"
              >
                See All Highlights
              </button>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default WeeklyHighlight;
