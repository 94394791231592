import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const dummyJobs = [
  {
    id: 1,
    title: 'Frontend Developer',
    company: 'Tech Solutions',
    location: 'San Francisco, CA',
    salary: '$90,000 - $120,000',
    description: 'Develop and maintain frontend components using React.',
    logo: 'https://via.placeholder.com/60',
  },
  {
    id: 2,
    title: 'Backend Developer',
    company: 'Innovate Inc.',
    location: 'New York, NY',
    salary: '$100,000 - $130,000',
    description: 'Work on server-side logic and database management.',
    logo: 'https://via.placeholder.com/60',
  },
  // Add more dummy jobs as needed
];

const Jobs = () => {
  return (
    <div className="container mx-auto px-4 py-6 min-h-screen">
      <h1 className="text-4xl font-bold text-center mb-10 text-gray-800">Job Listings</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {dummyJobs.map((job) => (
          <motion.div
            key={job.id}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300"
          >
            <div className="flex items-center p-4 border-b">
              <img
                src={job.logo}
                alt={job.company}
                className="w-16 h-16 object-cover rounded-full"
              />
              <div className="ml-4">
                <h2 className="text-2xl font-bold text-gray-900">{job.title}</h2>
                <p className="text-gray-700">{job.company}</p>
              </div>
            </div>
            <div className="p-4">
              <p className="text-gray-600 mb-2 flex items-center">
                <span className="material-icons text-gray-500 mr-1">location_on</span>
                {job.location}
              </p>
              <p className="text-gray-600 mb-2 flex items-center">
                <span className="material-icons text-gray-500 mr-1">attach_money</span>
                {job.salary}
              </p>
              <p className="text-gray-600 mb-4">{job.description}</p>
              <div className="flex justify-between">
                <Link
                  to={`/apply/${job.id}`}
                  className="bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300"
                >
                  Apply
                </Link>
                <Link
                  to={`/save/${job.id}`}
                  className="bg-gray-200 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-300 transition duration-300"
                >
                  Save
                </Link>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Jobs;
