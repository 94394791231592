import React from 'react';
import {
  HeroBanner,
  FeaturedCategories,
  WeeklyHighlights,
  UpcomingEvents,
  LatesBlogs,
  PopularPosts,
  ProjectSection,
  TutorialSection,
  UsefulResources,
  JobSection,
  FreelanceSection,
  CareerAdvice,
  UserStories,
  TeamMembers,
  CallToAction,
} from '../Components/index';

const Home = () => {
  return (
    <div className='container mx-auto px-4 py-8 space-y-0'>
      <HeroBanner />
      <FeaturedCategories />
      <WeeklyHighlights />
      <UpcomingEvents />
      <LatesBlogs />
      <PopularPosts />
      <ProjectSection />
      <TutorialSection />
      <UsefulResources />
      <JobSection />
      <FreelanceSection />
      <CareerAdvice />
      <UserStories />
      <TeamMembers />
      <CallToAction />
    </div>
  );
};

export default Home;
